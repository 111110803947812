/* eslint-disable no-bitwise */
import { FormInstance } from 'antd/lib/form';
import { ApplicationCapabilities, ApplicationDefinition, FormInputFieldData, FormInputParams, WidgetType } from '../services/types';
import { DynamicUiModel } from '../stores/DynamicUiModel';
import * as moment from 'moment-timezone';

export class Utils {
    static readonly uploadAcceptExtensions = '.doc,.docx,.xls,.xlsx,.pdf,.xltm,.xlsm';
    static htmlTags = /<(.|\n)*?>/g;
    private static symbols = /[()!.-1234567890:_=+@#$%^&*±[\]\n\t\\/]/gmi;
    private static spaces = /\s{2,}/gmi;    

    static clearText(text: string | null) {
        if (!text) {
            return text;
        }

        return text.replace(this.symbols, '').replace(this.spaces, ' ');
    }

    static readableFileSize(bytes: number, si: boolean) {
        var thresh = si ? 1000 : 1024;
        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }
        var units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        var u = -1;
        do {
            bytes /= thresh;
            ++u;
        } while (Math.abs(bytes) >= thresh && u < units.length - 1);
        return bytes.toFixed(1) + ' ' + units[u];
    }

    static formatDateStringLong (date: string | number | null) {
        if (date) {
            let dateVal: Date = new Date(date);
            if (dateVal instanceof Date && (!dateVal.getTime || isNaN(dateVal.getTime()))) {
                dateVal = new Date(Number.parseInt(date as string, 10));
            }
            return Intl.DateTimeFormat('en-Gb', {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            }).format(dateVal);
        } else {
            return '';
        }
    }

    static convertDateStringToMoment (date: string | number ) {
        let dateVal: Date = new Date(date);
        if (dateVal instanceof Date && (!dateVal.getTime || isNaN(dateVal.getTime()))) {
            dateVal = new Date(Number.parseInt(date as string, 10));
        }
        return moment(dateVal);
    }

    static formatDateLong (date: Date | null) {
        if (date) {
            return Intl.DateTimeFormat( this.getRegionLocales(), {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            
            }).format(date);
        } else {
            return '';
        }
    }

    static formatDateStringShort (date: string | null, excludeTime: boolean = false, excludeSeconds: boolean = false) {
        if (date) {
            return Intl.DateTimeFormat( this.getRegionLocales(), {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: excludeTime ? undefined: '2-digit',
                minute: excludeTime ? undefined: '2-digit',
                second: excludeSeconds ? undefined : '2-digit',
            }).format(new Date(date));
        } else {
            return '';
        }
    }

    static safeFormatDateString (date: string | null, format: string = 'YYYY-MM-DD', inputFormat: string = 'DD-MM-YYYY') {
        try {
            if (!date) { 
                return '';
            }
            
            let dateString = moment(new Date(date), inputFormat).format(format);
            return dateString.includes('Invalid') ? date : dateString;
        } catch {
            return date;
        }
    }

    static dateStringToIso (date: string | null) {
        try {
            if (!date) { 
                return '';
            }
            
            let dateString = new Date(date).toISOString();
            return dateString.includes('Invalid') ? date : dateString;
        } catch {
            return date;
        }
    }

    static extractFieldData(inputParams: FormInputParams) {
        if (inputParams.meta) {
            let tokenField = inputParams.meta.tokenFields && inputParams.tokenId ? inputParams.meta.tokenFields[inputParams.tokenId] : null;
            let data: FormInputFieldData;
            if (tokenField) {
                data = tokenField as FormInputFieldData;
            } else {
                data = inputParams.meta.field as FormInputFieldData;
            }

            if (data) {
                data.pId = inputParams.meta.packageId;
            }
            return data;
        }

        return null;
    }

    static extractFieldDataFromCombinedMeta(inputParams: FormInputParams) {
        if (inputParams.meta?.combinedMeta && Array.isArray(inputParams.meta.combinedMeta)) {
            let dataList: FormInputFieldData[] = [];
            let metaToParse = inputParams.meta.combinedMeta;
            for(let item of metaToParse) {
                let pId = item.key;
                let value = item.value;
                for (let key of Object.getOwnPropertyNames(value)) {
                    let data = value[key] as FormInputFieldData;
                    data.pId = pId;
                    dataList.push(data);
                }
            }
            return dataList;
        }

        return null;
    }

    static extractVisualTableRowData(inputParams: FormInputParams, ui: DynamicUiModel) {
        if (inputParams.controlType !== WidgetType.VisualTable || !inputParams.source) {
            return undefined;
        }

        const sources = ui.widgetValues[inputParams.source.id];

        if (!sources || !Array.isArray(sources) || !sources.length) {
            return undefined;
        }

        const tableSource = sources[0];

        if (tableSource.value && Array.isArray(tableSource.value)) {
            var valueArray = tableSource.value as Array<object>;
            var blocksData: {id: string; data: FormInputFieldData}[] = [];
            valueArray.forEach((record, index) => {
                var fields: FormInputFieldData[] = [];
                for (var key in record) {
                    if (record[key] && record[key].meta) {
                        fields.push(record[key].meta.field);
                    }
                }

                const hasValidData = fields.filter((f: FormInputFieldData) => f && f.x > -1).length > 0;

                if (hasValidData) { 
                    const max = fields.reduce((prev: FormInputFieldData, current: FormInputFieldData) => (prev.x > current.x) ? prev : current);
                    const min = fields.filter((f: FormInputFieldData) => f.x > -1)
                        .reduce((prev: FormInputFieldData, current: FormInputFieldData) => (prev.x < current.x) ? prev : current);
                    const maxHeight = fields.reduce((prev: FormInputFieldData, current: FormInputFieldData) => (prev.h > current.h) ? prev : current);
                    const fieldData: FormInputFieldData = {
                        x: min.x,
                        w: (max.x + max.w - min.x),
                        y: maxHeight.y,
                        h: maxHeight.h,
                        ph: min.ph,
                        pw: min.pw,
                        p: min.p,
                        bt: min.bt
                    };
                    blocksData.push({id: `${inputParams.id}-${index}`, data: fieldData});
                }
            });
            return blocksData;
        }

        return undefined;
    }

    static prepareRuntimeDataObjectFromForm(form: FormInstance, packageId: string | null = null) {
        const values = form!.getFieldsValue();
        var obj = {};

        if (packageId) {
            obj = {PackageId: {value: packageId}};
        }

        for (var key of Object.getOwnPropertyNames(values)) {
            obj[key] = {value: form.getFieldValue(key)};
        }

        return obj;
    }

    static insertWhitespaceBeforeCapital(s: string) {
        s = s.replace(/[^0-9a-z]/gi, '');
        s = s.replace(/([a-z])([A-Z])/g, '$1 $2');
        s = s.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
        s = s.replace(/([0-9])([a-z])/gi, '$1 $2');
        s = s.replace(/([a-z])([0-9])/gi, '$1 $2');
        return s;
    }

    static buildDynamicInputStyles(inputParams: FormInputParams) {
        let style: React.CSSProperties = {};
        if (inputParams.behavior && inputParams.behavior.style) {
            style = {... inputParams.behavior.style};
        }

        return style;
    }

    static stringToHash(s: string) {
        let hash = 0;
        for (let i = 0; i < s.length; i ++) {
            // tslint:disable-next-line:no-bitwise
            hash = s.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    }

    static intToRGB(i: number) {
        // tslint:disable-next-line:no-bitwise
        const c = (i & 0x00FFFFFF)
            .toString(16)
            .toUpperCase();
    
        return '00000'.substring(0, 6 - c.length) + c;
    } 

    static handleDropdownItemSelection (e: React.MouseEvent<HTMLElement, MouseEvent>, sel: Selection) {
        const getTextNode = (node: ChildNode): ChildNode => {
            return node.lastChild === null ? node : getTextNode(node.lastChild);
        };
        const el = e.target as HTMLElement;
        if (el.className !== 'ant-select-item-option-content' || e.button !== 2) {
            return;
        }
        el.focus();
        const range = document.createRange();
        const textNode = getTextNode(el);
        range.setStart(textNode!, 0);
        range.setEnd(textNode!, (textNode as Text).length);
        sel!.removeAllRanges();
        sel!.addRange(range);
    
    }
    
    static getApplicationDefinitionCapabilities(app: ApplicationDefinition) {
        const {meta} = app;
        if (!meta) {
            return null;
        }

        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const metaObj = JSON.parse(meta) as any;
            return metaObj.capabilities as ApplicationCapabilities;
        } catch {
            return null;
        }
    }

    static  safeStringLocaleCompare = (a: string | undefined , b: string | undefined ) => {
        if (!b && a) {
            return 1;
        } else if (b && !a) {
            return -1;
        } else if (!b && !a) {
            return 0;
        } else {
            return b!.localeCompare(a!);
        }
    };

    static safeDateCompare = (a: Date | undefined | null, b: Date | undefined | null) => {
        if (!a && b) {
            return -1;
        } else if (a && !b) {
            return 1;
        } else if (!a && !b) {
            return 0;
        } else {
            return a! > b! ? 1 : a! < b! ? - 1 : 0;
        }
    };

    static isExcelByExtension(name: string) {
        return name.toLowerCase().endsWith('xlsx') || name.toLowerCase().endsWith('xls') 
        || name.toLowerCase().endsWith('xltm') || name.toLowerCase().endsWith('xlsm');
    }

    static generateDocumentIconClass(documentName: string) {
        let colorClass: string = 'default';

        if (!documentName) {
            return colorClass;
        }

        const docNameLower = documentName.toLowerCase();

        if (docNameLower.endsWith('.pdf')) {
            colorClass = 'doc-pdf';
        }

        if (Utils.isExcelByExtension(docNameLower)) {
            colorClass = 'doc-xls';
        }

        if (docNameLower.endsWith('.doc') || docNameLower.endsWith('.docx')) {
            colorClass = 'doc-word';
        }

        if (docNameLower.endsWith('.xml')) {
            colorClass = 'doc-xml';
        }


        return colorClass;
    }

    static generateDocumentIcon(documentName: string) {
        const colorClass = Utils.generateDocumentIconClass(documentName);
        return `alpha-icon sm ${colorClass}`;
    }

    static getQuilModules() {
        return {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                [{'list': 'ordered'}, {'list': 'bullet'}],
            ]
        };
    }

    static getDateFormat() {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return timeZone.startsWith('America') ? 'MM/DD/YYYY' : 'DD/MM/YYYY';
    }

    static formatDatePickerValue(dateVal: string | null, excludeTime: boolean = false) {
        return dateVal ? moment(Utils.formatDateStringShort(dateVal, excludeTime), Utils.getDateFormat()) : undefined;
    }

    static addScript(src: string) {
        var s = document.createElement( 'script' );
        s.setAttribute( 'src', src );
        document.body.appendChild( s );
    }

    static isValidObjectId(id: string): boolean {
        const objectIdRegExp = /^[0-9a-fA-F]{24}$/;
        return objectIdRegExp.test(id);
    }

    private static getRegionLocales() {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return timeZone.startsWith('America') ? 'en-Us' : 'en-Gb';
    }
}
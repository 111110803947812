import { 
    SessionContinueRequest, 
    StartSessionResponse, 
    AllSessionsResult, 
    DataResponse, 
    SessionStates, 
    SessionsForProjects, 
    StartSessionForMultiplePackagesResponse, 
    PackageSetSessionResult,
    SessionModelResult,
    SessionState,
    IotaAppCommentsResult,
    SessionFilter
} from './types';
import { execQuery } from '.';
import { gql } from 'apollo-boost';
import appClient from './AppClient';

export type Filter = {
    [key: string]: unknown
};

export default class SessionService {
    getSessionState(sessionId: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `iotaruntime/applications/sessions/${sessionId}`;
        return appClient.get<SessionState>(url);       
    }

    getSessionStateFromIota(runtimeSessionId: string, extension: string) {
        const url = process.env.REACT_APP_IOTA_URL + `runtime/${extension}/${runtimeSessionId}`;
        return appClient.get<SessionState>(url); 
    }

    updateSession(sessionId: string, state: SessionStates) {
        const request = {
            id: sessionId,
            state: state
        };
        const url = process.env.REACT_APP_MANAGE_URL + 'iotaruntime/applications/sessions';
        return appClient.update(url, request);
    }

    startSession(applicationId: string, packageId: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `iotaruntime/applications/${applicationId}/sessions/${packageId}`;
        return appClient.post<StartSessionResponse>(url, {});
    }

    startSessionForPackageSet(applicationId: string, packageSetId: string, isBulk: boolean = true) {
        const url = process.env.REACT_APP_MANAGE_URL + `iotaruntime/applications/${applicationId}/process-set/${packageSetId}?isBulk=${isBulk}`;
        return appClient.post<string[]>(url, {});
    }

    startSessionForMultiplePackages(applicationId: string, packageIds: string[]) {
        var request = {
            packageIds
        };
        const url = process.env.REACT_APP_MANAGE_URL + `iotaruntime/applications/${applicationId}/process-packages`;
        return appClient.post<StartSessionForMultiplePackagesResponse>(url, request);
    }

    continueSession(values: {}, actionId: string, runtimeSessionId: string, extension: string) {
        const requestBody: SessionContinueRequest = {
            inputs: values,
            action: actionId
        };

        const url = process.env.REACT_APP_IOTA_URL + `runtime/${extension}/${runtimeSessionId}`;
        return appClient.post(url, requestBody);
    }

    
    // eslint-disable-next-line max-len
    async loadValuesForWidgets(sessionId: string, link: string, applicationId: string, extension: string, settings: unknown, page: number = 0, pageSize: number = 9999, filter: (Filter | undefined) = undefined, parentValues?: string[] | null) {
        const url = process.env.REACT_APP_IOTA_URL + `connections/${extension}/${applicationId}/${link}`;
        const request = {
            page: page,
            pageSize: pageSize,
            connectionId: link,
            fields: [
                'text',
                'value'
            ],
            filters: filter ? [{
                'filters': Object.getOwnPropertyNames(filter).map(f => ({ 'field': f, 'value': filter[f], 'operator': 'Contains' })),
                'logicalOperation': 'and'
            }] : undefined,
            parentInputValues: parentValues
        };

        const requestBody = {
            request,
            settings
        };

        return appClient.post<DataResponse>(url, requestBody);
    }

    async getAllSessions() {
        var result = await execQuery<AllSessionsResult>({
            query: gql`query getSessionModels {
                getSessionModels {
                        id,
                        packageId,
                        runtimeSessionId,
                        created,
                        updated,
                        applicationDefinitionId,
                        error,
                        applicationName,
                        applicationExtension,
                        applicationSettings,
                        packageName,
                        packageSetId,
                        projectId,
                        projectName,
                        state,
                        user,
                        iotaApplication,
                        isBulkSession,
                        isReloadable,
                        capabilities,
                        updatedBy,
                        sessionResults
                    }
                }`,
            fetchPolicy: 'network-only'
        });

        if (result.errors) {
            return [];
        }

        return result.data.getSessionModels || [];
    }

    async getSessionsForProjects(filter?: SessionFilter) {
        const result = await execQuery<SessionsForProjects>({
            query: gql`
                query getSessionModelsForProjects($filter: SearchSessionsForProjectsFilter) {
                    getSessionModelsForProjects(filter: $filter) {
                        sessions {
                            id
                            packageId
                            runtimeSessionId
                            created
                            updated
                            applicationDefinitionId
                            error
                            applicationName
                            applicationExtension
                            applicationSettings
                            packageName
                            projectId
                            packageSetId
                            projectName
                            state
                            userId
                            iotaApplication
                            isBulkSession
                            isReloadable
                            capabilities
                            updatedBy,
                            sessionResults
                        }
                        total
                    }
                }
            `,
            variables: { filter },
            fetchPolicy: 'network-only'
        });

        if (result.errors) {
            return {
                sessions: [],
                total: 0
            };
        }

        return {
            sessions: result.data.getSessionModelsForProjects.sessions,
            total: result.data.getSessionModelsForProjects.total
        };
    }

    async getPackageSetSession(id: string) {
        const result = await execQuery<PackageSetSessionResult>({
            query: gql`query getPackageSetSessionById($id:String) {
                getPackageSetSessionById(id:$id) {
                        id,
                        packages
                    }
                }`,
            variables: {
                id
            },
            fetchPolicy: 'network-only'
        });
        
        if (result.errors) {
            return undefined;
        }

        return result.data.getPackageSetSessionById || undefined;
    }

    async getFileFromIotaTempStorage(filePath: string) {
        const url = `${process.env.REACT_APP_IOTA_URL}files/download`;
        const resp = await appClient.post(url, {filePath}, 'blob');
        resp.map(data => {
            const blobURL = URL.createObjectURL(data as Blob);
            const tempLink = document.createElement('a');
            tempLink.style.display = 'none';
            tempLink.href = blobURL;
            tempLink.setAttribute('download', filePath.replace(/^.*[\\/]/, ''));
            if (typeof tempLink.download === 'undefined') {
                tempLink.setAttribute('target', '_blank');
            }
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            setTimeout(() => {
                URL.revokeObjectURL(blobURL);
            },         100);
        });
    }

    async getSessionById(sessionId: string, projectIds?: string[]) {
        const result = await execQuery<SessionModelResult>({
            /* eslint-disable max-len */
            query: gql`query getSessionModelById($sessionId:String!, $projectIds: [String]) {
                getSessionModelById(sessionId: $sessionId, projectIds: $projectIds) {
                    id,
                    packageId,
                    runtimeSessionId,
                    created,
                    updated,
                    applicationDefinitionId,
                    error,
                    applicationName,
                    applicationExtension,
                    applicationSettings,
                    packageName,
                    projectId,
                    packageSetId,
                    projectName,
                    state,
                    userId,
                    iotaApplication,
                    isBulkSession,
                    isReloadable,
                    capabilities,
                    sessionResults
                }
            }`,
            variables: {
                sessionId: sessionId,
                projectIds: projectIds
            },
            fetchPolicy: 'network-only'
        });

        if (result.data.getSessionModelById?.length) {
            return result.data.getSessionModelById[0];
        }

        return null;
    }

    async getComments(sessionId: string) {
        const result = await execQuery<IotaAppCommentsResult>({
            query: gql`query getIotaAppComments($sessionId:String!) {
                getIotaAppComments(sessionId:$sessionId) {
                        fieldId,
                        comments {
                            userId,
                            value,
                            date
                        }
                    }
                }`,
            variables: {
                sessionId
            },
            fetchPolicy: 'network-only'
        });
        
        if (result.errors) {
            return [];
        } 

        return result.data.getIotaAppComments || [];
    }

    async saveFieldComment(sessionId: string, fieldId: string, comment: string) {
        const requestBody = {
            comment
        };
        const url = `${process.env.REACT_APP_IOTA_URL}appfieldcomments/applications/sessions/${sessionId}/field-comment/${fieldId}`;
        return appClient.post(url, requestBody);
    }
}
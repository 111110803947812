import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { WidgetValuesProvider } from '../../common/services/WidgetValuesProvider';
import { SessionService } from '../../common/services';
import { WidgetDataItem } from '../../common/services/types';
import { BusinessDto } from '../types';
import AppStore from './AppStore';

export default class BusinessSearchStore {
    modalVisible: boolean = false;

    businessPartnersProvider: WidgetValuesProvider;
    businessPartners: WidgetDataItem[] = [];
    businessPartnersLoading: boolean = false;

    businessesProvider: WidgetValuesProvider;
    businesses: BusinessDto[] = [];
    businessesLoading: boolean = false;

    page: number = 0;
    pageSize: number = 10;

    readonly requestPage: number = 0;
    readonly requestPageSize: number = 1000;

    constructor(private readonly appStore: AppStore, private readonly sessionService: SessionService) {
        this.businessPartnersProvider = new WidgetValuesProvider(
            this.sessionService,
            'BusinessPartners',
            this.appStore.sessionId,
            this.appStore.applicationData
        );

        this.businessesProvider = new WidgetValuesProvider(
            this.sessionService,
            'Businesses',
            this.appStore.sessionId,
            this.appStore.applicationData
        );

        makeObservable(this, {
            businessPartners: observable,
            businesses: observable,
            businessPartnersLoading: observable,
            businessesLoading: observable,
            modalVisible: observable,
            page: observable,
            pageSize: observable,
            setModalVisible: action,
            businessPartnersOptions: computed
        });

        this.getBusinessPartners();
    }

    updatePaging(page: number, pageSize: number) {
        runInAction(() => {
            this.page = page;
            this.pageSize = pageSize;
        });
    }

    setModalVisible(modalVisible: boolean) {
        this.modalVisible = modalVisible;
    }

    async getBusinessPartners() {
        try {
            runInAction(() => {
                this.businessPartnersLoading = true;
            });

            const businessPartners = await this.businessPartnersProvider.fetchAll();

            runInAction(() => {
                this.businessPartners = businessPartners.data;
            });
        } finally {
            runInAction(() => {
                this.businessPartnersLoading = false;
            });
        }
    }

    async getBusinesses(filter: Record<string, unknown> = {}) {
        try {
            runInAction(() => {
                this.businessesLoading = true;
            });

            const businesses = await this.businessesProvider.fetchByPage(this.requestPage, this.requestPageSize, {
                ...filter,
                page: this.requestPage.toString(),
                pageSize: this.requestPageSize.toString()
            });

            runInAction(() => {
                this.businesses = businesses.data.map(b => JSON.parse(b.value) as BusinessDto);
            });
        } finally {
            runInAction(() => {
                this.businessesLoading = false;
            });
        }
    }

    get businessPartnersOptions() {
        return this.businessPartners.map(p => ({ label: p.text, value: p.value, title: p.text }));
    }
}

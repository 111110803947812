import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../stores';
import { Menu, Row, Col, Tooltip } from 'antd';
import _ from 'lodash';
import { TabModel } from '../types/TabModel';
import { Utils } from '../misc/Utils';
import { useLocation, useNavigate } from 'react-router';
import { sessionsPath, analysisPath } from '../../analysis/routes';
import { TabPathEvent } from '../stores/TabsStore';

export const DocumentTabs: React.FC = () => {
    const { tabsStore } = useStore();
    const [hiddenTabsCount, setHiddenTabsCount] = React.useState(0);
    const location = useLocation();
    const navigate = useNavigate();

    const calculateHiddenTabs = React.useCallback(() => {
        const visibleTabsContainer = document.getElementsByClassName('document-tabs-container');
        let visibleTabsCount = 0;

        if (visibleTabsContainer && visibleTabsContainer[0]) {
            const tabsContainer = visibleTabsContainer[0];
            const tabs = tabsContainer.getElementsByClassName('document-tab');
            visibleTabsCount = Array.from(tabs).filter(x => (x as HTMLElement).style.opacity !== '0').length;
        }   
        setHiddenTabsCount(tabsStore.tabs.length - visibleTabsCount);
    }, [tabsStore.tabs.length]);

    React.useEffect(() => {
        calculateHiddenTabs();
        window.addEventListener('resize', _.debounce( calculateHiddenTabs, 200));

        return(() => {
            window.removeEventListener('resize', calculateHiddenTabs);
        });
    },              [calculateHiddenTabs]);

    React.useEffect(() => {
        if (tabsStore.tabs) {
            tabsStore.resetSelection();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    React.useEffect(() => {
        // Timeout needed to let tab be moved to submenu first
        setTimeout(calculateHiddenTabs, 100);
    },              [calculateHiddenTabs, tabsStore.tabs.length]);

    React.useEffect(() => {
        const handlePathReset = () => {
            if (location.pathname !== analysisPath) {
                navigate(analysisPath);
            }
        };

        const handlePathUpdate = (event: TabPathEvent) => {
            if (!event.tabId) {
                return;
            }

            const newPathName = `${sessionsPath}/${event.tabId}`;

            if (location.pathname !== newPathName) {
                navigate(newPathName);
            }
        };

        const subscribtion = tabsStore.tabPathSubject.subscribe(event => {
            switch (event.action) {
            case 'resetPath':
                handlePathReset();
                break;
            case 'updatePath':
                handlePathUpdate(event);
                break;
            default:
                break;
            }
        });

        return () => subscribtion.unsubscribe();
    }, [tabsStore, location, navigate]);

    const selectTab = (tabIndex: number) => {        
        tabsStore.selectTab(tabIndex);
    };

    const closeTab = (tabIndex: number) => {
        if (tabsStore.tabs.length > 1 && tabsStore.tabs[tabIndex].isSelected) {
            let newTabIndex = tabIndex === 0 ? 1 : tabIndex - 1;
            tabsStore.selectTab(newTabIndex);
        }
        tabsStore.removeTab(tabIndex);
    };

    const determineMenuSelection = () => {
        if (tabsStore.selectedTab) {
            return [`tab-${tabsStore.tabs.indexOf(tabsStore.selectedTab)}`];
        }

        return [];
    };

    if (!tabsStore.tabs || tabsStore.tabs.length === 0) {
        return null;
    }

    const getTabTitle = (t: TabModel) => {
        if (t.metadata && t.metadata.packageSetId) {
            if (t.type === 'multidocument') {
                return (
                    <>
                        <i className="alpha-icon xs middle package-set" />
                        <span className="title-text">{t.title}</span> 
                    </>
                );
            }

            return (
                <>
                    <i className="alpha-icon xs middle package-set" />
                    <span className="title-container">
                        <div className="main-title-text">{t.title.split(',').length} {t.title.split(',').length === 1 ? 'document' : 'documents'}</div> 
                        <div className="subtitle-text">{t.title}</div> 
                    </span>
                </>
            );
        } else {
            const className = Utils.isExcelByExtension(t.title) ? 'package-xls-icon' : 'package-icon';
            return (
                <>
                    <i className={`alpha-icon xs middle ${className}`} />
                    <span className="title-text">{t.title}</span> 
                </>
            );
        }
    };

    return (
        <Menu
            theme="light"
            mode="horizontal"
            subMenuCloseDelay={1}
            style={{ lineHeight: '50px' }}
            className="document-tabs-container"
            selectedKeys={determineMenuSelection()}
            overflowedIndicator={<span style={{display: 'inline-block', width: 150}}><i className="alpha-icon xs overflow-indicator" /> {hiddenTabsCount} more...</span>}
        >
            {tabsStore.tabs && tabsStore.tabs.length > 0 && tabsStore.tabs.map((t, i) => (
                <Menu.Item
                    data-id={`tab-${t.id}`}
                    key={`tab-${i}`}
                    className={`document-tab ${t.isSelected ? 'selected' : ''}`}
                    style={{ height: 50, lineHeight: '50px' }}
                >
                    <Row align="middle">
                        <Col span={18} onClick={() => selectTab(i)}>
                            <Tooltip title={t.title} autoAdjustOverflow>
                                <span className="tab-title">
                                    {getTabTitle(t)}
                                </span>
                            </Tooltip>
                        </Col>
                        <Col span={6} style={{ textAlign: 'right' }}>
                            <span className="btn-container" onClick={() => closeTab(i)}>
                                <span className="document-tab-btn">
                                    <i className="alpha-icon xs nav-close-tab middle" />
                                </span>
                            </span>
                        </Col>
                    </Row>
                </Menu.Item>
            ))}
        </Menu>
    );
};

export default observer(DocumentTabs);
import * as React from 'react';
import { DynamicControlProps } from './types';
// import { WidgetData } from '../../../common/services/types';
import { Form, Empty, Table, Button, Modal, Input, Spin, Tooltip, Row, Tag, Radio } from 'antd'; // Tooltip,
import { LoadingOutlined } from '@ant-design/icons';
import { SorterResult, SortOrder, TablePaginationConfig } from 'antd/lib/table/interface';
import { FormInputFieldData, WidgetData, DisplayColumnsBehavior } from '../../../common/services/types';
import * as moment from 'moment-timezone';
import { Subscription } from 'rxjs';
import { DynamicUiUtils } from '../../../common/services';
import { DynamicVisualTableModal } from './DynamicVisualTableModal';
import { observer } from 'mobx-react-lite';
import { Utils } from '../../../common/misc/Utils';

const DynamicVisualTable: React.FC<DynamicControlProps> = ({ inputParams, ui, form, onHighlightBlock, highlightInputSubject, getGearIcon, onAction }) => {
    const [selectedRecord, setSelectedRecord] = React.useState<object | undefined>(undefined);
    const [selectedRecordIndex, setSelectedRecordIndex] = React.useState<number | undefined>(undefined);
    const [editDialogVisible, setEditDialogVisible] = React.useState<boolean>(false);
    const [currentVal, setCurrentVal] = React.useState<object[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [sources, setSources] = React.useState<{[key: string]: WidgetData}>({});
    const [preventTableScroll, setPreventTableScroll] = React.useState<boolean>(false);
    const [filteredTableRows, setFilteredTableRows] = React.useState<object[]>([]);
    const [tableFilter, setTableFilter] = React.useState<string>('all');
    const [recordEditForm] = Form.useForm();
    const [highlightedInput, setHighlightedInput] = React.useState<string>('');
    const initialPage = inputParams.behavior?.currentPage && inputParams.behavior?.currentPage > 0 ? inputParams.behavior?.currentPage : 1;
    const [currentPage, setCurrentPage] = React.useState<number>(initialPage);
    const [pageSize, setPageSize] = React.useState<number>(50);
    let sub: React.MutableRefObject<Subscription | undefined> = React.useRef();
    const sortedColumnKey = inputParams.behavior?.sortedColumn?.key;

    const highlightHtmlElement = React.useCallback((inputId: string) => {
        const key = inputId.replace(`${inputParams.id}-`, '');
        const htmlElement = document.querySelector(`[data-row-key="${key}"]`) as HTMLElement;
        if (htmlElement) {
            htmlElement.scrollIntoView();
        }
    }, [inputParams.id]);

    
    const getRowKey = React.useCallback((record: object) => {
        const rowIndexKey = 'ALPHA:RowIndex';
        if (record[rowIndexKey] != null) {
            return record[rowIndexKey];
        }

        const keys = Object.getOwnPropertyNames(record);

        if (!keys?.length) {
            return currentVal.indexOf(record);
        }

        const rowNumber = record[keys[0]]?.meta?.field?.y;

        if (!rowNumber) {
            return currentVal.indexOf(record);
        }

        return rowNumber;
    }, [currentVal]);

    const calculatePage = React.useCallback((key: string) => {
        const record = filteredTableRows.find(r => getRowKey(r)?.toString() === key);

        if (!record) {
            return;
        }

        const index = filteredTableRows.indexOf(record);
        return Math.floor(index / pageSize) + 1;
    }, [filteredTableRows, getRowKey, pageSize]);

    const loadSources = React.useCallback(async () => {
        if (!inputParams.behavior || !inputParams.behavior.inputOverride) {
            return;
        }
        let sourceObj: {[key: string]: WidgetData} = {};
        const sourceKeys = [... new Set(inputParams.behavior.inputOverride.filter(i => !!i.source).map(i => i.source))];
        for (var sourceKey of sourceKeys) {
            const values = await ui.getAllWidgetValues(sourceKey);
            sourceObj[sourceKey] = values;
        }
        setSources(sourceObj);
    }, [inputParams.behavior, ui]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const highlightField = (record: any) => {
        setPreventTableScroll(true);
        var fields: FormInputFieldData[] = [];
        for (var key in record) {
            if (record[key] && record[key].meta) {
                fields.push(record[key].meta.field);
            }
        }
        const max = fields.reduce((prev: FormInputFieldData, current: FormInputFieldData) => (prev.x > current.x) ? prev : current);
        const min = fields.filter((f: FormInputFieldData) => f.x > -1).reduce((prev: FormInputFieldData, current: FormInputFieldData) => (prev.x < current.x) ? prev : current);
        const maxHeight = fields.reduce((prev: FormInputFieldData, current: FormInputFieldData) => (prev.h > current.h) ? prev : current);
        const highlightFieldData: FormInputFieldData = {
            x: min.x,
            w: (max.x + max.w - min.x),
            y: maxHeight.y,
            h: maxHeight.h,
            ph: min.ph,
            pw: min.pw,
            p: min.p,
            bt: min.bt
        };

        onHighlightBlock(highlightFieldData, `${inputParams.id}-${getRowKey(record)}`);
    };

    // Didn't wrap in react callback with further passing into effect dependencies due to caused performance issues and lags
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const mapSourceValues = () => {
        if (!inputParams?.behavior?.inputOverride) {
            return;
        }

        const { inputOverride } = inputParams.behavior;
        const overridesWithSources = inputOverride.filter(o => o.source != null);

        if (!overridesWithSources?.length) {
            return;
        }

        const isArray = Array.isArray(currentVal);
        const arrayVal = currentVal as Array<unknown>;

        if (!isArray || !arrayVal) {
            return;
        }

        let newVal = [];
        for(let val of arrayVal) {
            const keys = Object.getOwnPropertyNames(val);
            let valObj = val as object;
            let newValObj = {...valObj};

            for (let key of keys) {
                const override = overridesWithSources.find(o => o.key === key);
                
                if (!override) {
                    continue;
                }

                const source = sources[override.source];

                if (!source) {
                    continue;
                }
                const valueField = 'value';
                let valueToSearch = valObj[key][valueField];

                if (override.multiSelect && typeof(valueToSearch) === 'string' && valueToSearch.includes(',')) {
                    valueToSearch = valueToSearch.split(',').map(v => v.trim());
                }

                let value: string | string[] | undefined = undefined;
                if (Array.isArray(valueToSearch)) {
                    value = [];
                    for(let item of valueToSearch) {
                        const foundVal = source.data.find(v => v.text === item || v.value === item)?.value;
                        if (foundVal) {
                            value.push(item);
                        }
                    }
                } else {
                    value = source.data.find(v => v.text === valueToSearch || v.value === valueToSearch)?.value;
                }
                newValObj[key][valueField] = value;
            }
            
            newVal.push(newValObj);
        }

        setCurrentVal(newVal);
    };

    React.useEffect(() => {
        if (highlightedInput && highlightedInput.includes(inputParams.id) && !preventTableScroll) {
            const key = highlightedInput.replace(`${inputParams.id}-`, '');
            const page = calculatePage(key);
            if (page === currentPage) {
                highlightHtmlElement(highlightedInput);
            } else {
                setCurrentPage(page || 1);
                setTimeout(() => {
                    highlightHtmlElement(highlightedInput);
                }, 0);
            }
        } else if (preventTableScroll) {
            setTimeout(() => {
                setPreventTableScroll(false);
            },         0);
        }
    // Disabled exhaustive deps due to it being triggered too frequently
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },            [highlightedInput]);

    React.useEffect(() => {
        if (sources && currentVal?.length) {
            mapSourceValues();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentVal?.length, sources]);
    
    React.useEffect(() => {
        try {
            setIsLoading(true);
            if (inputParams.value) {
                setCurrentVal(inputParams.value as object[]);
                setFilteredTableRows(inputParams.value as object[]);
                setIsLoading(false);
            } else {
                ui.getWidgetArrayValueFromSource(inputParams.source.id).then(r => {
                    const obj = {};
                    obj[inputParams.id] = r === undefined ? r : ((r instanceof Array) ? (r.length ? r[0].value : undefined) : r);
                    const val = r === undefined ? [] : ((r instanceof Array) ? (r.length ? r[0].value as object[] : []) : []);
                    form.setFieldsValue(obj);
                    setCurrentVal(val);
                    setFilteredTableRows(val);
                    setIsLoading(false);
                });
            }

            if (highlightInputSubject) {
                sub.current = highlightInputSubject.subscribe(id => setHighlightedInput(id));
            }

            loadSources()
                .then(() => {
                    if (ui.validateOnLoad && inputParams.behavior?.validationAction) {
                        const runtimeInputs = Utils.prepareRuntimeDataObjectFromForm(form);

                        ui.runValidationAction(runtimeInputs, inputParams.behavior.validationAction).catch(() => {
                            ui.setValidateOnLoad(false);
                        });
                    }
                })
                .catch(() => {
                    if (ui.validateOnLoad) {
                        ui.setValidateOnLoad(false);
                    }
                });
        } catch (err) {
            setIsLoading(false);
        }
    
        return (() => {
            if (sub.current) {
                sub.current.unsubscribe();
            }
        });
    },              [form, highlightInputSubject, inputParams.id, inputParams.source.id, inputParams.behavior?.validationAction, inputParams.value, loadSources, ui]);

    const getColumnOrder = (key: string) => {
        return sortedColumnKey  === key ? inputParams.behavior!.sortedColumn?.order : undefined;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getTooltipTitle = (dc: DisplayColumnsBehavior, val: any) => {
        let tooltipTitle = val;

        if (typeof tooltipTitle !== 'string') {
            return tooltipTitle;
        }

        if (dc.key === 'Result' && dc.valueFilters && Array.isArray(dc.valueFilters)) {
            tooltipTitle = dc.valueFilters.reduce((acc, filter) => acc.replace(filter, ''), tooltipTitle).trim();
        }

        return tooltipTitle;
    };
    
    const buildColumns = () => {
        const isArray = Array.isArray(currentVal);
        const arrayVal = currentVal as Array<unknown>;
        if (isArray && arrayVal) {
            let columns: {
                title: string; 
                key: string;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                render: (text: string, record: any, index: number) => any;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                sorter?: (a: any, b: any) => -1 | 0 | 1;
                sortDirections?: SortOrder[];
                defaultSortOrder?: SortOrder;
                width?: number
            }[] = [];

            if (inputParams.behavior && inputParams.behavior.displayColumns) {
                inputParams.behavior.displayColumns.forEach(dc => {
                    const key = `${inputParams.id}-col-${dc.key}`;
                    columns.push({
                        title: dc.header,
                        key: key,
                        sortDirections: ['ascend', 'descend'],
                        sorter: (a, b) => {
                            if (!a[dc.key]) {
                                return -1;
                            }
                            if (!b[dc.key]) {
                                return 1;
                            }
                            const val1 = getRecordValue(a, dc.key, dc.source);
                            const val2 = getRecordValue(b, dc.key, dc.source);
                            return val1 > val2 ? 1 : val1 < val2 ? -1 : 0;
                        },
                        defaultSortOrder: getColumnOrder(key),
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        render: (_: string, record: any) => {
                            var val = getRecordValue(record, dc.key, dc.source);
                            return (
                                <Tooltip overlayStyle={{maxWidth: '600px', whiteSpace: 'pre-wrap'}} placement="topLeft" title={getTooltipTitle(dc, val)}>
                                    <div style={{ maxWidth: 300, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        {getRecordDisplayVal(val, dc.key, record.HasErrors)}
                                    </div>
                                </Tooltip>
                            );
                        }
                    });
                });
            } else {
                var keys = Object.getOwnPropertyNames(currentVal[0]);
                keys.forEach(k => {
                    columns.push({
                        title: k,
                        key: `${inputParams.id}-col-${k}`,
                        sortDirections: ['ascend', 'descend'],
                        sorter: (a, b) => {
                            if (!a[k]) {
                                return -1;
                            }
                            if (!b[k]) {
                                return 1;
                            }
                            
                            const val1 = getRecordValue(a, k);
                            const val2 = getRecordValue(b, k);
                            return val1 > val2 ? 1 : val1 < val2 ? -1 : 0;
                        },
                        
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        render: (_: string, record: any) => {
                            var val = getRecordValue(record, k);
                            return (
                                <Tooltip overlayStyle={{maxWidth: '600px', whiteSpace: 'pre-wrap'}} placement="topLeft" title={val}>
                                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                        {getRecordDisplayVal(val, k, record.HasErrors)}
                                    </div>
                                </Tooltip>
                            );
                        }
                    });
                });
            }

            columns.push({
                title: '',
                width: 60,
                key: `${inputParams.id}-col-actions`,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                render: (text: string, record: any, index: number) => {
                    return(
                        <div className="row-actions">
                            <Tooltip title="Edit">
                                <Button 
                                    shape="circle" onClick={(event) => openEditDialog(event, record)} 
                                    icon={<i className="alpha-icon xs edit-pen" />} 
                                    data-id={`edit-record-${inputParams.id}-${index}`}
                                />
                            </Tooltip>
                        </div>
                    );
                }
            });

            return columns;
        }

        return undefined;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getRecordValue = (record: any, key: string, source?: string | undefined) => {
        let val = record[key] ? record[key].value : '';

        if (val == null) {
            return val;
        }

        if (inputParams.behavior && inputParams.behavior.inputOverride) {
            const inputOverride = inputParams.behavior.inputOverride.find(i => i.key === key);
            if (inputOverride && source) {
                if (Array.isArray(val)) {
                    const sourceVals = sources[source] ? sources[source].data.filter(d => val.includes(d.value)) : undefined;

                    if (sourceVals) {
                        const displayValues = sourceVals.map(v => v.text);
                        return displayValues.join(', ');
                    }
                }

                const sourceVal = sources[source] ? sources[source].data.find(d => d.value === val) : undefined; 
                if (sourceVal) {
                    return sourceVal.text;
                }
            }

            try {
                val = val as string;
                val = val.replace(/\\n/g, '\n').replace(/\\t/g, '\t').replace(/\\u0027/g, '"'); // .replace('\\t', '\t').replace('\\u0027', '"');
                return val;
            } catch {
                return record[key].value;
            }
        }

        val = val.replace('\\n', '\n').replace('\\t', '\t').replace('\\u0027', '"');
        return val;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getRecordDisplayVal = (val: any, key: string, hasErrors: boolean = false) => {
        if (key === 'Result') {
            return getResultDisplay(val, hasErrors);
        }

        if (key === 'State') {
            return DynamicUiUtils.getStateDisplayVal(val);
        }

        return val;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getResultDisplay = (val: any, hasErrors: boolean = false) => {
        let recordResult = val ? val as string : undefined;
        let resultMsgLower = recordResult ? recordResult.toLowerCase() : undefined;
        if (resultMsgLower && (resultMsgLower.includes('error') || resultMsgLower.includes('exception') || hasErrors === true)) {
            return (<Tag color="#F5222D">Failed</Tag>);
        } else if (resultMsgLower && resultMsgLower.includes('warning')) {
            return <Tag color="#FF8300">Warning</Tag>;
        } else if (resultMsgLower && resultMsgLower.includes('successful')) {
            return (<Tag color="#52C41A">Completed</Tag>);
        } else {
            return null;
        }
    };


    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleTableOnchange = (pagination: TablePaginationConfig, filters: any, sorter: SorterResult<any>) => {
        const obj = {};
        obj[inputParams.id] = currentVal;
        obj[`${inputParams.id}-current_page`] = pagination.current;
        obj[`${inputParams.id}-sorted_column`] = {key: sorter.columnKey, order: sorter.order};
        form.setFieldsValue(obj);
    };

    const renderTable = () => {
        if (!Array.isArray(currentVal)) {
            return null;
        }

        const rowSelection = {
            onChange: (selectedRowKeys: string[]) => {
                let obj = {};
                obj[inputParams.id + '-selected'] = selectedRowKeys;
                form.setFieldsValue(obj);
            },
            getCheckboxProps: (record: { name: string }) => ({
                name: record.name,
            }),
        };

        // TODO: Change table scrolling behaviour
        return(
            <Table 
                rowSelection={{
                    type: 'checkbox',
                    columnWidth: 46,
                    ...rowSelection
                }}
                scroll={{y: 240}}
                rowKey={(record: object) => {
                    return getRowKey(record); 
                }}
                className={`dynamic-visual-table ${filteredTableRows.length <= pageSize ? 'no-pages' : ''}`}
                dataSource={filteredTableRows}
                columns={buildColumns()}
                onRow={(record) => {
                    return {
                        onClick: () => highlightField(record)
                    }; 
                }}
                onChange={handleTableOnchange}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                rowClassName={(record: any) => {
                    let className = '';
                    if (highlightedInput === `${inputParams.id}-${getRowKey(record)}`) {
                        className = 'highlighted-row';
                    }

                    let recordResult = record.Result && record.Result.value ? record.Result.value as string : undefined;
                    let resultMsgLower = recordResult ? recordResult.toLowerCase() : undefined;
                    if (resultMsgLower && (resultMsgLower.includes('error') || resultMsgLower.includes('exception')) || record.HasErrors === true) {
                        className += ' error-row';
                    } else if (resultMsgLower && resultMsgLower.includes('warning')) {
                        className += ' warning-row';
                    } else if (resultMsgLower && resultMsgLower.includes('successful')) {
                        className += ' success-row';
                    }

                    return className;
                }}
                pagination={{
                    hideOnSinglePage: true,
                    pageSize: pageSize,
                    current: currentPage,
                    onChange: (current: number, size: number) => {
                        setCurrentPage(current);
                        setPageSize(size);
                    }
                }}
            />
        );
    };

    const renderEmpty = () => {
        if (isLoading) {
            const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
            return(
                <div style={{textAlign: 'center'}}>
                    <Spin indicator={loadingIcon} />
                </div>
            );
        }

        return (
            <Empty />
        );
    };


    const handleValueChange = (changedValues: object) => {
        ui.setCurrentFieldsChange(changedValues);
    };

    const buildEditInputs = () => {
        if (!selectedRecord) { 
            return null;
        }
        let inputs: (JSX.Element | null)[] = [];
        let fields: string[] = [];

        if (inputParams.behavior && inputParams.behavior.displayColumns) {
            fields = inputParams.behavior.displayColumns.map(dc => dc.key);
        }

        fields = fields.concat(Object.getOwnPropertyNames(selectedRecord));
        
        if (inputParams?.behavior?.systemInputs) {
            fields = fields.concat(inputParams.behavior.systemInputs.map(x => x.key));
        }
        fields = [...new Set(fields)];

        if (inputParams.behavior && inputParams.behavior.visibleInputs) {
            fields = inputParams.behavior.visibleInputs;
        }

        fields = fields.filter(r => !['Actions', 'Result'].includes(r));

        fields.forEach(r => {
            inputs.push(
                <DynamicVisualTableModal 
                    key={r}
                    selectedRecord={selectedRecord}
                    sources={sources}
                    inputParams={inputParams}
                    ui={ui}
                    onAction={onAction}
                    getGearIcon={getGearIcon}
                    recordEditForm={recordEditForm}
                    onHighlightBlock={onHighlightBlock}
                    id={r}/>);
        });

        return (
            <Form 
                onValuesChange={handleValueChange}
                form={recordEditForm} 
                layout="vertical"
                className="dynamic-row-edit-form" 
                data-id={`record-edit-form-${inputParams.id}`}
            >
                <Row>
                    {inputs && inputs.map(i => i)}
                </Row>
            </Form>);
    };

    const openEditDialog = (event: React.MouseEvent, record: object) => {
        event.stopPropagation();
        const recordObj = (JSON.parse(JSON.stringify(record)));
        setSelectedRecord(recordObj);
        setSelectedRecordIndex(getRowKey(record));
        setEditDialogVisible(true);
    };

    const closeEditDialog = () => {
        setEditDialogVisible(false);
        setSelectedRecord(undefined);
        recordEditForm.resetFields();
        setSelectedRecordIndex(undefined);
    };

    const updateRecord = () => {
        if (!selectedRecord || selectedRecordIndex === undefined) {
            return;
        }

        const formObj = recordEditForm.getFieldsValue();
        Object.getOwnPropertyNames(formObj).forEach(k => {
            const formVal = recordEditForm.getFieldValue(k); 
            const recordVal = moment.isMoment(formVal) ? formVal.format('YYYY-MM-DD') : formVal;
            if (selectedRecord[k]) {
                selectedRecord[k].value = recordVal;
            } else {
                selectedRecord[k] = {
                    value: recordVal
                };
            }
        });

        let newList = [...currentVal];
        newList[selectedRecordIndex] = selectedRecord;
        setCurrentVal(newList);
        changeRowFilter(tableFilter, newList);
        let obj = {};
        obj[inputParams.id] = newList;
        form.setFieldsValue(obj);

        if (inputParams.behavior?.validationAction) {
            const runtimeInputs = Utils.prepareRuntimeDataObjectFromForm(form);
            ui.runValidationAction(runtimeInputs, inputParams.behavior.validationAction);
        }

        closeEditDialog();
    };

    const changeRowFilter = (value: string, filterTarget = currentVal) => {
        setTableFilter(value);
        const resultKey = 'Result';
        switch (value) {
        case 'unprocessed':
            setFilteredTableRows(filterTarget.filter((obj => !obj[resultKey] || !obj[resultKey].value)));
            break;
        case 'completed':
            setFilteredTableRows(filterTarget.filter(obj => 
                obj[resultKey] 
                    && obj[resultKey].value
                    && (obj[resultKey].value.toLowerCase().includes('update') || obj[resultKey].value.toLowerCase().includes('create'))
                    && !obj[resultKey].value.toLowerCase().includes('error')
                    && !obj[resultKey].value.toLowerCase().includes('exception')));
            break;
        case 'failed':
            setFilteredTableRows(filterTarget.filter(obj => 
                obj[resultKey] 
                    && obj[resultKey].value
                    && (obj[resultKey].value.toLowerCase().includes('error') || obj[resultKey].value.toLowerCase().includes('exception'))));
            break;
        case 'all':
        default:
            setFilteredTableRows(filterTarget);
            break;
        }
    };

    const renderRowFilters = () => {
        const resultKey = 'Result';
        const totalCount = currentVal.length;
        const errorsCount = currentVal.filter(obj => obj[resultKey] && obj[resultKey].value 
            && (obj[resultKey].value.toLowerCase().includes('error') || obj[resultKey].value.toLowerCase().includes('exception'))).length;
        const createdCount = currentVal.filter(obj => obj[resultKey] 
            && obj[resultKey].value 
            && obj[resultKey].value.toLowerCase().includes('create') 
            && !obj[resultKey].value.toLowerCase().includes('error')
            && !obj[resultKey].value.toLowerCase().includes('exception')).length;
        const updatedCount = currentVal.filter(obj => obj[resultKey] 
            && obj[resultKey].value 
            && obj[resultKey].value.toLowerCase().includes('update') 
            && !obj[resultKey].value.toLowerCase().includes('error')
            && !obj[resultKey].value.toLowerCase().includes('exception')).length;

        const completedCount = updatedCount + createdCount;
        const unprocessedCount = totalCount - errorsCount - completedCount;
        const options = [
            {label: 'All', value: 'all' },
            {label: `Unprocessed (${unprocessedCount})`, value: 'unprocessed'},
            {label: `Completed (${completedCount})`, value: 'completed'},
            {label: `Failed (${errorsCount})`, value: 'failed'}, ];
        return (
            <div className="visual-table-filters">
                <Radio.Group
                    options={options}
                    onChange={(e) => changeRowFilter(e.target.value)}
                    value={tableFilter}
                    optionType="button"
                    buttonStyle="solid"
                />
            </div>
        );
    };

    return (
        <>
            <Modal
                title={inputParams.behavior?.header ?? 'Edit claim'}
                maskClosable={false}
                visible={editDialogVisible}
                onCancel={() => closeEditDialog()}
                zIndex={1001}
                width={700}
                closable={false}
                destroyOnClose
                footer={[
                    <Button 
                        data-id="claim-cancel-button"
                        key="claim-cancel-button" 
                        size="large"
                        className="light alpha-dialog-button"
                        onClick={closeEditDialog}
                    >
                        Cancel
                    </Button>,
                    <Button 
                        data-id="claim-save-button"
                        key="claim-save-button" 
                        type="primary"
                        size="large"
                        className="alpha-dialog-button"
                        onClick={updateRecord}
                    >
                        Save
                    </Button>
                ]}
                className="dynamic-row-edit-modal"
            >
                {buildEditInputs()}
            </Modal>
            {renderRowFilters()}
            <Form.Item style={{display: 'none'}} name={`${inputParams.id}-selected`}><Input type="hidden" /></Form.Item>
            <Form.Item style={{display: 'none'}} name={`${inputParams.id}-current_page`}><Input type="hidden" /></Form.Item>
            <Form.Item style={{display: 'none'}} name={`${inputParams.id}-sorted_column`}><Input type="hidden" /></Form.Item>
            <Form.Item
                name={inputParams.id}
                initialValue={currentVal}
                data-id={inputParams.id}
                style={{width: '100%'}}
            >
                {currentVal ? renderTable() : renderEmpty()}
            </Form.Item>
        </>
    );
};

export default observer(DynamicVisualTable);
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { InputModel } from '../../models';
import { InputWithoutSource } from '../../componentTypes';
import { CustomInputNumberWrapper, InputActions, CustomFormItem } from '../../components';
import { FieldValidators } from '../../misc';

const config = {
    label: 'Number of Reinstatements'
};

const CountOfReinstatementsInput: InputWithoutSource = ({ input }) => (
    <CustomFormItem name={input.guid} customValidator={(...args) => FieldValidators.numberValidator(...args, 1, 5)}>
        <CustomInputNumberWrapper input={input} label={config.label} topRightNode={<InputActions input={input} />} />
    </CustomFormItem>
);

CountOfReinstatementsInput.meta = {
    inputType: InputModel.name
};

export default observer(CountOfReinstatementsInput);

import * as React from 'react';
import { TabBaseModel, InputBaseModel } from '../models';
import { RequiredConditionDto } from '../types';
import { RequiredConditionsValidator } from '../misc';

type InputWithRequiredConditions = InputBaseModel & { requiredConditions: RequiredConditionDto[] };

export default (tab: TabBaseModel) => {
    const validateRequiredConditions = (inputs: InputBaseModel[], inputId: string) => {
        inputs
            .filter(
                (input): input is InputWithRequiredConditions =>
                    !!(
                        input.requiredConditions &&
                        input.requiredConditions.some(c => c.fields.some(f => f.fieldId === inputId))
                    )
            )
            .forEach(input => {
                const validConditionResults = RequiredConditionsValidator.validateConditions(
                    input.requiredConditions,
                    inputs
                );

                input.setRequired(validConditionResults.some(r => r.isValid));
            });
    };

    const handleRequiredConditions = React.useCallback(
        (input: InputBaseModel) => {
            const inputs = tab.getInputsAtSameLevel(input.guid);
            validateRequiredConditions(inputs, input.id);
        },
        [tab]
    );

    return {
        handleRequiredConditions
    };
};

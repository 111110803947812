import * as React from 'react';
import { Tooltip } from 'antd';
import { ApplicationSession } from '../../services/types';

interface Props {
    session: ApplicationSession
}

const CompletedSessionIcon: React.FC<Props> = ({ session }: Props) => {
    const getSessionResultsTooltip = () => {
        if (!session.sessionResults) {
            return null;
        }

        const lines = Object.entries(session.sessionResults).reduce<string[]>(
            (acc, [key, value]) => [...acc, `${key}: ${value}`],
            []
        );

        if (!lines.length) {
            return null;
        }

        return (
            <div>
                {lines.map((line, index) => (
                    <React.Fragment key={index}>
                        <span>{line}</span>
                        <br />
                    </React.Fragment>
                ))}
            </div>
        );
    };

    return (
        <Tooltip title={getSessionResultsTooltip()}>
            <span
                data-id-name="Status"
                className="closed-session-status"
                style={{ background: '#9BA0AA', fontSize: 11 }}
            >
                Completed
            </span>
        </Tooltip>
    );
};

export default CompletedSessionIcon;

import { makeObservable, observable, action } from 'mobx';
import { LayerWithSectionsDto } from '../../types';
import { SectionModel, SourceModelBase, TabBaseModel } from '..';
import { UiUtils } from '../../misc';

export default class LayerWithSectionsModel {
    readonly id: string;

    readonly name: string;

    sections: SectionModel[] = [];

    expanded: boolean = false;

    constructor(private readonly tab: TabBaseModel, data: LayerWithSectionsDto, sources: SourceModelBase[]) {
        this.id = data.id;
        this.name = data.name;
        this.sections = data.sections.map(section => new SectionModel(this.tab, section, sources));

        makeObservable(this, {
            sections: observable,
            expanded: observable,
            update: action,
            setExpanded: action.bound,
            toggleExpanded: action.bound
        });

        this.setExpanded(this.sections.some(section => UiUtils.shouldExpandCollapse(section.inputs)));
    }

    setExpanded(expanded: boolean) {
        this.expanded = expanded;
    }

    toggleExpanded() {
        this.setExpanded(!this.expanded);
    }

    getSectionByInputGuid(guid: string) {
        for (const section of this.sections) {
            if (section.inputs.some(input => input.guid === guid)) {
                return section;
            }
        }

        return null;
    }

    update(data: LayerWithSectionsDto, sources: SourceModelBase[]) {
        data.sections.forEach(section => {
            const existingSection = this.sections.find(s => s.id === section.id);

            if (!existingSection) {
                this.sections.push(new SectionModel(this.tab, section, sources));
            } else {
                existingSection.update(section, sources);
            }
        });

        this.sections = this.sections.filter(section => data.sections.some(s => s.id === section.id));
    }

    get dto(): LayerWithSectionsDto {
        return {
            id: this.id,
            name: this.name,
            sections: this.sections.map(section => section.dto)
        };
    }
}

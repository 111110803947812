import * as React from 'react';

export default (open?: boolean) => {
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

    React.useEffect(() => {
        if (open !== undefined) {
            setIsDropdownOpen(open);
        }
    }, [open]);

    React.useEffect(() => {
        if (!isDropdownOpen) {
            return;
        }

        const handleDropdownClose = (event: Event) => {
            const target = event.target as HTMLElement | null;

            if (!target || target.closest('.rc-virtual-list-holder')) {
                return;
            }

            setIsDropdownOpen(false);
        };

        window.addEventListener('scroll', handleDropdownClose, true);

        return () => window.removeEventListener('scroll', handleDropdownClose, true);
    }, [isDropdownOpen]);

    return {
        isDropdownOpen,
        setIsDropdownOpen
    };
};

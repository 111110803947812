import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { TabBaseModel } from '../../models';
import { ExclamationCircleIcon } from '../../../custom_shared/icons';

interface Props {
    tab: TabBaseModel
}

const TabHeader: React.FC<Props> = ({ tab }) => {
    return (
        <span className="tab-header">
            {tab.hasError ? <ExclamationCircleIcon /> : null}
            {tab.name}
        </span>
    );
};

export default observer(TabHeader);

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { GenericSelectMultiple } from '../../components';
import { InputModelWithSource } from '../../models';
import { ValidationMessages } from '../../misc';
import { InputWithSource } from '../../componentTypes';

const config = {
    label: 'Main class'
};

const MainClassInput: InputWithSource = ({ input }) => (
    <GenericSelectMultiple
        label={config.label}
        input={input}
        message={ValidationMessages.requiredInput(config.label)}
    />
);

MainClassInput.meta = {
    inputType: InputModelWithSource.name
};

export default observer(MainClassInput);

import * as React from 'react';
import ReactDOM from 'react-dom';

type ButtonPosition = { top: number; left: number };

type TextSelectionData = {
    buttonPosition: ButtonPosition;
    selectedText: string
};

const textSelectionButtonStyle: React.CSSProperties = {
    position: 'fixed',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    color: '#fff',
    fontSize: '12px',
    padding: '6px 10px',
    borderRadius: '4px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    zIndex: 9999
};

export default (containerRef: React.RefObject<HTMLDivElement>) => {
    const [textSelectionData, setTextSelectionData] = React.useState<TextSelectionData | null>(null);

    const copyToClipboard = async (selectedText: string) => {
        try {
            await navigator.clipboard.writeText(selectedText);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    const clearTextSelection = React.useCallback(() => {
        if (!textSelectionData) {
            return;
        }

        const selection = window.getSelection();

        if (selection) {
            selection.removeAllRanges();
        }

        setTextSelectionData(null);
    }, [textSelectionData]);

    const handleTextSelection = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.button !== 2) {
            return;
        }

        event.preventDefault();

        setTimeout(() => {
            const target = event.target as HTMLElement;
            const selection = window.getSelection();

            if (!selection || target.className !== 'ant-select-item-option-content' || !target.textContent) {
                return;
            }

            const rect = target.getBoundingClientRect();
            const range = document.createRange();

            range.selectNodeContents(target);
            selection.removeAllRanges();
            selection.addRange(range);

            setTextSelectionData({
                selectedText: target.textContent,
                buttonPosition: {
                    top: rect.top - rect.height - 15 + window.scrollY,
                    left: event.clientX + window.scrollX
                }
            });
        }, 0);
    };

    const renderTextSelectionButton = (): React.ReactNode | null => {
        if (!textSelectionData) {
            return null;
        }

        return ReactDOM.createPortal(
            <div
                style={{
                    ...textSelectionButtonStyle,
                    top: textSelectionData.buttonPosition.top,
                    left: textSelectionData.buttonPosition.left
                }}
                onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    copyToClipboard(textSelectionData.selectedText);
                    clearTextSelection();
                }}
                onMouseDown={event => {
                    event.preventDefault();
                    event.stopPropagation();
                }}
                onMouseUp={event => {
                    event.preventDefault();
                    event.stopPropagation();
                }}
            >
                Copy
            </div>,
            document.body
        );
    };

    const handleClickOutside = React.useCallback(
        (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                clearTextSelection();
            }
        },
        [containerRef, clearTextSelection]
    );

    React.useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, [handleClickOutside]);

    return {
        handleTextSelection,
        clearTextSelection,
        renderTextSelectionButton
    };
};

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { InputBaseModel } from '../../models';
import { InputRenderer } from '../../components';
import { TabProps } from '../../componentTypes';
import {
    CurrencyTypeInput,
    CalculationRuleInput,
    ReinstatementsTypeInput,
    CountOfReinstatementsInput,
    PaymentRuleInput,
    AmountPercentInput,
    TimePercentInput,
    BrokerageTypeInput,
    BrokeragePercentInput
} from '../../tab_inputs/ReinstatementsTabInputs';
import { LayoutRenderer } from '../../layout_renderers';
import { Utils } from '../../misc';
import { useTabForm } from '../../hooks';

const InputIds = {
    CurrencyType: 'CurrencyType',
    CalculationRule: 'CalculationRule',
    ReinstatementsType: 'ReinstatementsType',
    CountOfReinstatements: 'CountOfReinstatements',
    PaymentRule: 'PaymentRule',
    AmountPercent: 'AmountPercent',
    TimePercent: 'TimePercent',
    BrokerageType: 'BrokerageType',
    BrokeragePercent: 'BrokeragePercent'
} as const;

const InputComponents: Record<string, React.FunctionComponent> = {
    [InputIds.CurrencyType]: CurrencyTypeInput,
    [InputIds.CalculationRule]: CalculationRuleInput,
    [InputIds.ReinstatementsType]: ReinstatementsTypeInput,
    [InputIds.CountOfReinstatements]: CountOfReinstatementsInput,
    [InputIds.PaymentRule]: PaymentRuleInput,
    [InputIds.AmountPercent]: AmountPercentInput,
    [InputIds.TimePercent]: TimePercentInput,
    [InputIds.BrokerageType]: BrokerageTypeInput,
    [InputIds.BrokeragePercent]: BrokeragePercentInput
} as const;

const ReinstatementsTabRenderer: React.FC<TabProps> = ({ tab, addFormRef }: TabProps) => {
    const { form, formKey, onValuesChange } = useTabForm(tab, addFormRef);

    const getComponentRows = React.useCallback(
        (inputs: InputBaseModel[]) =>
            Utils.groupArray(inputs, 4).map(inputGroup =>
                inputGroup.map(input => (
                    <InputRenderer key={input.id} input={input} component={InputComponents[input.id]} />
                ))
            ),
        []
    );

    return (
        <LayoutRenderer
            tab={tab}
            form={form}
            formKey={formKey}
            onValuesChange={onValuesChange}
            getComponentRows={getComponentRows}
        />
    );
};

export default observer(ReinstatementsTabRenderer);

import * as React from 'react';
import { observer, Observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import { debounce } from 'lodash';
import { SessionsVisualStore } from '../../stores';
import { ApplicationSession, SessionFilter } from '../../../common/services/types';
import { Tooltip, Table, Card, Select, DatePicker } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { generateDocumentIcon } from '../../../common/components/DocumentIconRenderer';
import Search from 'antd/lib/input/Search';
import { Utils } from '../../../common/misc/Utils';
import { hasPermission, HasPermission } from '../../../authorization/components/HasPermission';
import { AppPermissions } from '../../../authorization/Permissions';
import authContext from '../../../authorization/AuthContext';
import { useNavigate } from 'react-router';
import { CompletedSessionIcon } from '../../../common/components';
import './SessionsList.less';

type Props = {
    store: SessionsVisualStore
};

export const SessionsList: React.FC<Props> = ({ store }) => {
    const navigate = useNavigate();
    const authCtx = React.useContext(authContext);
    React.useEffect(() => {
        const loadForCurrentUserOnly = hasPermission(authCtx.permissions, AppPermissions.CanSeeAnalysisPagePersonal) 
            && !hasPermission(authCtx.permissions, AppPermissions.CanSeeAnalysisPageAll);

        store.setLoadForCurrentUserOnly(loadForCurrentUserOnly);
        store.loadArchiveSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },              [store]);

    const generateStatusIcon = (session: ApplicationSession) => {
        if (session.error) {
            return (
                <Tooltip title={session.error}>
                    <span data-id-name="Status" className="closed-session-status" style={{background: '#F5222D', fontSize: 11}}>Failed</span>
                </Tooltip>
            );
        }
        switch (session.state) {
        case 'Finished':
            return <CompletedSessionIcon session={session} />;
        case 'Archived':
            return (
                <span data-id-name="Status" className="closed-session-status" style={{background: '#FA8C16', fontSize: 11}}>Please review</span>
            );
        default: return;
        }
    };

    const handleReloadClick = (session: ApplicationSession) => {
        navigate('/analysis');
        store.startSessionFromExisting(session);
    };

    const generateActionButtons = (session: ApplicationSession) => {
        return (
            <div className="closed-session-operation">
                <HasPermission permissionClaim={AppPermissions.CanManageSessionsAndDocuments}>
                    {session.isReloadable !== false 
                        ? (
                            <Tooltip title="Start from scratch">
                                <i className={'alpha-icon xs reload'} onClick={() => handleReloadClick(session)}/>
                            </Tooltip>) 
                        : null}
                </HasPermission>
                
                <Tooltip title="Switch to Analysis form">
                    <i className={'alpha-icon xs session-switch'} onClick={() => switchToSession(session)} />
                </Tooltip>
            </div>
        );
    };

    const formatDate = (date: string | null) => {
        if (date) {
            return Intl.DateTimeFormat('en-Gb', {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            }).format(new Date(date));
        } else {
            return '';
        }
    };

    const switchToSession = (session: ApplicationSession) => {
        store.switchToSession(session);
    };

    const columns = [
        {
            title: 'Document',
            render: (a: ApplicationSession) => (
                <div className="label-with-icon archive-doc-name">
                    {generateDocumentIcon(a.packageName, !!a.packageSetId)} 
                    <Tooltip title={a.packageName}>
                        <span data-id-name="Package">{a.packageName}</span>
                    </Tooltip>
                </div>)
        }, {
            title: 'Application',
            render: (a: ApplicationSession) => (<span data-id-name="Application">{a.applicationName}</span>)
        }, {
            title: 'Project',
            render: (a: ApplicationSession) => (<span data-id-name="Project">{a.projectName}</span>)
        }, {
            title: 'Created By',
            render: (a: ApplicationSession) => (<span data-id-name="User">{store.allUsers[a.userId!]}</span>)
        }, {
            title: 'Created',
            // eslint-disable-next-line max-len
            render: (a: ApplicationSession) => (<span data-timestamp={Utils.dateStringToIso(formatDate(a.created))} data-id-name="Created">{Utils.formatDateStringShort(a.created, false, true)}</span>)
        }, {
            title: 'Updated By',
            render: (a: ApplicationSession) => (<span data-id-name="UpdatedBy">{a.updatedBy ? store.allUsers[a.updatedBy] || 'System' : ''}</span>)
        }, {
            title: 'Updated',
            // eslint-disable-next-line max-len
            render: (a: ApplicationSession) => (<span data-timestamp={Utils.dateStringToIso(formatDate(a.updated))} data-id-name="Updated">{Utils.formatDateStringShort(a.updated, false, true)}</span>)
        },{
            title: 'Status',
            render: (a: ApplicationSession) => generateStatusIcon(a),
            width: '95px'
        }, {
            render: (a: ApplicationSession) => generateActionButtons(a),
            width: '95px'
        }];

    const updateFilterAndLoadSessions = React.useCallback(
        (filter: Partial<SessionFilter>) => {
            store.setArchiveSessionsParams({
                ...store.archiveSessionsParams,
                filter: {
                    ...store.archiveSessionsParams.filter,
                    ...filter,
                    page: filter.page ?? 0,
                    pageSize: filter.pageSize ?? store.archiveSessionsParams.filter.pageSize
                }
            });
            store.loadArchiveSessions();
        },
        [store]
    );

    const debouncedSearch = React.useMemo(
        () =>
            debounce((searchTerm: string) => {
                updateFilterAndLoadSessions({ searchTerm });
            }, 500),
        [updateFilterAndLoadSessions]
    );

    const pagination: TablePaginationConfig = {
        pageSizeOptions: ['10', '15', '20', '50', '100'],
        pageSize: store.archiveSessionsParams.filter.pageSize,
        total: store.archiveSessionsParams.total,
        current: store.archiveSessionsParams.filter.page + 1,
        onChange: (page, pageSize) => {
            updateFilterAndLoadSessions({ page: page - 1, pageSize: pageSize ?? store.archiveSessionsParams.filter.pageSize });
        }
    };

    return (
        <HasPermission permissionClaim={AppPermissions.CanAccessArchive}>
            <div className="sessions-list-header">
                <h1 className="header-title">Archive</h1>

                {(!store.loadForCurrentUserOnly && store.hasAccessToAllEntities) && (
                    <Select
                        className="user-select"
                        placeholder="Select user"
                        onChange={(value?: string) => updateFilterAndLoadSessions({ userId: value })}
                        options={store.allUsersList.map(user => ({ value: user.id, label: user.name, title: user.name }))}
                        filterOption={(input, option) => option?.title && option.title.toLowerCase().includes(input.toLowerCase())}
                        showSearch   
                        allowClear
                    />
                )}

                <DatePicker.RangePicker
                    className="date-picker"
                    onChange={(dateRange: [Moment, Moment]) => {
                        let dateFrom: string | undefined;
                        let dateTo: string | undefined;

                        if (dateRange) {
                            dateFrom = moment.utc(dateRange[0]).startOf('day').toISOString();
                            dateTo = moment.utc(dateRange[1]).endOf('day').toISOString();
                        }

                        updateFilterAndLoadSessions({ dateFrom, dateTo });
                    }}
                />

                <Search
                    className="search"
                    placeholder="Search..."
                    onChange={e => debouncedSearch(e.target.value)}
                    enterButton
                    maxLength={150}
                    allowClear
                    prefix={<i className="alpha-icon xs search" style={{ marginRight: 10 }} />}
                />
            </div>
            <Card style={{border: 'none'}} className="content-card table-content">    
                <Observer>
                    {() => (
                        <Table 
                            data-id="alpha-archive-table"
                            className="alpha-table no-border-top"
                            rowKey={r => r.id}
                            onRow={(record) => {
                                return {
                                    'data-id': record.id,
                                    id: record.id
                                };
                            }}
                            pagination={pagination}
                            columns={columns} 
                            tableLayout='fixed'
                            dataSource={store.archiveSessions} 
                            loading={store.archiveSessionsLoading}
                        />
                    )}
                </Observer>
            </Card>
        </HasPermission>
    );
};

export default observer(SessionsList);

/* eslint-disable @typescript-eslint/member-ordering */
import { action, runInAction, observable, computed, makeObservable } from 'mobx';
import { WorkflowInstanceStore, ApplicationDefinitionsStore, DocumentTabStore, MultiDocumentTabStore } from '.';
import { DocumentVisualStore } from '../../documents/stores';
import { TabModel } from '../types/TabModel';
import { Subject } from 'rxjs';

export type TabPathEvent = { action: 'updatePath' | 'resetPath'; tabId?: string };

export default class TabsStore {
    tabs: TabModel[] = [];
    
    documentStores: { [id: string]: DocumentVisualStore} = {};

    appStores: {[id: string]: ApplicationDefinitionsStore} = {};

    documentTabStores: {[id: string]: DocumentTabStore} = {};

    multiDocumentTabStores: {[id: string]: MultiDocumentTabStore} = {};

    workflowInstances:  {[id: string]: WorkflowInstanceStore} = {};

    dynamicUiLoadRetries: number = 0;

    tabUiLoadingSubject: Subject<string> = new Subject();

    sessionTabReloading: {sessionId: string; packageName: string; packageId: string} | undefined = undefined;

    tabPathSubject: Subject<TabPathEvent> = new Subject();

    get selectedTab() {
        if (this.tabs) {
            return this.tabs.find(t => t.isSelected);
        }
        return undefined;
    }

    get documentTabs() {
        if (this.tabs) {
            return this.tabs.filter(t => t.type === 'document');
        }
        return null;
    }

    get multidocumentTabs() {
        if (this.tabs) {
            return this.tabs.filter(t => t.type === 'multidocument');
        }
        return null;
    }

    constructor() {
        makeObservable(this, {
            tabs: observable,
            sessionTabReloading: observable,
            selectedTab: computed,
            documentTabs: computed,
            multidocumentTabs: computed,
            setSessionTabReloading: action.bound,
            addTab: action.bound,
            removeTab: action.bound,
            resetSelection: action.bound,
            selectTab: action.bound
        });
    }

    setSessionTabReloading(
        tab: {sessionId: string; packageName: string; packageId: string} | undefined
    ) {
        this.sessionTabReloading = tab;
    }

    async addTab(tab: TabModel) {        
        if (!this.tabs.find(t => t.id === tab.id)) {        
            if (tab.isSelected) {
                this.tabs.forEach(t => t.isSelected = false);
            }
                     
            this.tabs.push(tab);
            this.tabPathSubject.next({ action: 'updatePath', tabId: tab.id });
        } else {
            const existingTab = this.tabs.find(t => t.id === tab.id);
            this.selectTab(this.tabs.indexOf(existingTab!));
        }     
    }
    
    removeTab(index: number) {
        if (index === -1) {
            return;
        }

        const tab = this.tabs[index];
        if (tab) {            
            this.tabs.splice(index, 1);
            if (this.documentStores[tab.id]) {
                delete this.documentStores[tab.id]; 
            }
            if (this.appStores[tab.id]) {
                delete this.appStores[tab.id]; 
            }
            if (this.documentTabStores[tab.id]) {
                delete this.documentTabStores[tab.id]; 
            }
            if (this.workflowInstances[tab.id]) {
                delete this.workflowInstances[tab.id]; 
            }
            if (this.multiDocumentTabStores[tab.id]) {
                delete this.multiDocumentTabStores[tab.id]; 
            }

            // Remove additional stores of tabs
            var storeKeys = Object.getOwnPropertyNames(this.documentStores);
            for (let key of storeKeys) {
                if (key.includes(tab.id)) {
                    delete this.documentStores[key];
                }
            }
        }

        if (!this.tabs.length) {
            this.tabPathSubject.next({ action: 'resetPath' });
        }
    }

    resetSelection() {
        this.tabs.forEach(t => t.isSelected = false);
    }

    selectTab(index: number, updatePath: boolean = true) {
        if (this.tabs && this.tabs[index]) {
            runInAction(() => {
                this.tabs.forEach(t => t.isSelected = false);
                this.tabs[index].isSelected = true;
            });

            if (updatePath) {
                this.tabPathSubject.next({ action: 'updatePath', tabId: this.tabs[index].id });
            }
        }
    }
}
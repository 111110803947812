import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CustomTextAreaWrapper, InputActions, CustomFormItem } from '../../components';
import { InputModel } from '../../models';
import { ValidationMessages } from '../../misc';
import { InputWithoutSource } from '../../componentTypes';

const config = {
    label: 'Contract name',
    maxLength: 60
};

const TitleInput: InputWithoutSource = ({ input }) => {
    return (
        <CustomFormItem
            name={input.guid}
            required={input.required}
            message={ValidationMessages.requiredInput(config.label)}
        >
            <CustomTextAreaWrapper
                input={input}
                label={config.label}
                topRightNode={<InputActions input={input} />}
                maxLength={config.maxLength}
            />
        </CustomFormItem>
    );
};

TitleInput.meta = {
    inputType: InputModel.name
};

export default observer(TitleInput);

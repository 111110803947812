import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { GenericDatePicker } from '../../components';
import { InputModel } from '../../models';
import { InputWithoutSource } from '../../componentTypes';

const config = {
    label: 'Expiring period start'
};

const ExpiringPeriodStartInput: InputWithoutSource = ({ input }) => (
    <GenericDatePicker label={config.label} input={input} />
);

ExpiringPeriodStartInput.meta = {
    inputType: InputModel.name
};

export default observer(ExpiringPeriodStartInput);

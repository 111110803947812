import { action, makeObservable, observable } from 'mobx';

export default class RestricedAccessStore {
    restricedModeEnabled: boolean = false;
    restrictedSessionInitializing: boolean = false;

    constructor() {
        makeObservable(this, {
            restricedModeEnabled: observable,
            restrictedSessionInitializing: observable,
            setRestrictedModeEnabled: action,
            setRestrictedSessionInitializing: action
        });
    }

    setRestrictedModeEnabled(restricedModeEnabled: boolean) {
        this.restricedModeEnabled = restricedModeEnabled;
    }

    setRestrictedSessionInitializing(restrictedSessionInitializing: boolean) {
        this.restrictedSessionInitializing = restrictedSessionInitializing;
    }
}

import * as React from 'react';
import { Input, InputProps } from 'antd';
import { CustomInputLabel } from '..';
import { useSelectedElementStatus } from '../../hooks';
import './CustomInput.less';

export interface CustomInputProps extends Omit<InputProps, 'placeholder'> {
    label: string;
    required?: boolean;
    topRightNode?: React.ReactNode;
    containerStyle?: React.CSSProperties;
    hasError?: boolean;
    getContainerRef?: (containerRef: React.RefObject<HTMLDivElement>) => void
}

const CustomInput: React.FC<CustomInputProps> = (props: CustomInputProps) => {
    const { label, required, topRightNode, containerStyle, hasError, getContainerRef, ...inputProps } = props;

    const containerRef = React.useRef<HTMLDivElement>(null);

    const { hasSelectedElement } = useSelectedElementStatus(containerRef, 'input[value]:not([value=""])');

    const ref = React.useRef<Input>(null);

    React.useEffect(() => {
        if (getContainerRef) {
            getContainerRef(containerRef);
        }
    }, [getContainerRef]);

    const getContainerClassName = () => {
        let className = 'custom-input-container';

        if (hasError) {
            className += ' has-error';
        }

        return className;
    };

    const getInputClassName = () => {
        let className = 'custom-input';

        if (inputProps.allowClear) {
            className += '-allow-clear';
        }

        if (hasSelectedElement) {
            className += ' not-empty';
        }

        return className;
    };

    return (
        <div ref={containerRef} className={getContainerClassName()} style={containerStyle}>
            {!!topRightNode && <div className="top-right-node-container">{topRightNode}</div>}

            <Input className={getInputClassName()} ref={ref} {...inputProps} autoComplete="off" />

            <CustomInputLabel label={label} required={required} onClick={() => ref.current?.focus()} />
        </div>
    );
};

export default CustomInput;

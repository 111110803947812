/* eslint-disable max-len */
import * as React from 'react';
import { IconProps } from '../componentTypes';

const ExclamationCircleIcon: React.FC<IconProps> = ({ width = 16, height = 16, color = '#F5222D', ...props }) => (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M8.24463 13.5521C11.1509 13.5521 13.5571 11.1407 13.5571 8.23962C13.5571 5.33337 11.1457 2.92712 8.23942 2.92712C5.33838 2.92712 2.93213 5.33337 2.93213 8.23962C2.93213 11.1407 5.34359 13.5521 8.24463 13.5521ZM8.24463 12.6667C5.7863 12.6667 3.82275 10.698 3.82275 8.23962C3.82275 5.78129 5.78109 3.81254 8.23942 3.81254C10.6978 3.81254 12.6665 5.78129 12.6717 8.23962C12.6769 10.698 10.703 12.6667 8.24463 12.6667ZM8.23942 9.18233C8.48421 9.18233 8.63005 9.04171 8.63525 8.77087L8.71338 6.02087C8.71859 5.75525 8.51025 5.55733 8.23421 5.55733C7.95296 5.55733 7.75505 5.75004 7.76025 6.01567L7.82796 8.77087C7.83317 9.0365 7.979 9.18233 8.23942 9.18233ZM8.23942 10.875C8.5363 10.875 8.79671 10.6355 8.79671 10.3334C8.79671 10.0261 8.5415 9.79171 8.23942 9.79171C7.93213 9.79171 7.67692 10.0313 7.67692 10.3334C7.67692 10.6302 7.93734 10.875 8.23942 10.875Z"
            fill={color}
        />
    </svg>
);

export default ExclamationCircleIcon;

import * as React from 'react';
import HomePage from '../screens/Analysis';
import { Route, Routes } from 'react-router';
import { context } from '../stores';
import { context as sessionContext } from '../../sessions/stores';
import { context as documentsContext } from '../../documents/stores';

export const analysisPath = '/analysis';
export const sessionsPath = '/sessions';

const ProvidersWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <documentsContext.DocumentsStoreProvider>
        <sessionContext.SessionsStoreProvider>
            <context.AnalysisStoreProvider>{children}</context.AnalysisStoreProvider>
        </sessionContext.SessionsStoreProvider>
    </documentsContext.DocumentsStoreProvider>
);

const AnalysisRoute: React.FC = () => {
    return (
        <ProvidersWrapper>
            <Routes>
                <Route path={analysisPath} element={<HomePage />} />
                <Route path={`${sessionsPath}/:sessionId`} element={<HomePage />} />
            </Routes>
        </ProvidersWrapper>
    );
};

const analysisRoute = () => <Route path="/*" element={<AnalysisRoute />} />;

export default analysisRoute;

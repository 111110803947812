import { Regex, ValidationMessages } from '.';
import { TypeUtils } from '../../custom_shared/misc';

export default class FieldValidators {
    static unlimitedFieldValidator(_: unknown, value: unknown, setError: (error: string) => void) {
        if (TypeUtils.isString(value) && value.trim().length > 0 && !Regex.unlimitedRegex.test(value)) {
            setError(ValidationMessages.unlimitedFieldValidationMessage);
            return Promise.reject(new Error(ValidationMessages.unlimitedFieldValidationMessage));
        }

        setError('');
        return Promise.resolve();
    }

    static numberValidator(_: unknown, value: unknown, setError: (error: string) => void, min?: number, max?: number) {
        const resetFieldError = () => {
            setError('');
            return Promise.resolve();
        };

        const setFieldError = (error: string) => {
            setError(error);
            return Promise.reject(new Error(error));
        };

        if (!TypeUtils.isNumber(value) || isNaN(value)) {
            return resetFieldError();
        }

        if (min !== undefined && value < min) {
            return setFieldError(`The number must be greater than or equal to ${min}`);
        }

        if (max !== undefined && value > max) {
            return setFieldError(`The number must be less than or equal to ${max}`);
        }

        return resetFieldError();
    }
}

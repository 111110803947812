import * as React from 'react';
import LocalStorageWorker from '../misc/StorageHelper';
import { useLocalStore } from 'mobx-react-lite';
import * as services from '../services';
import * as stores from '.';
import UserProfileService from '../services/UserProfileService';
function createStore() {
    const iotaSessionStore = new stores.IotaSessionsStore();
    const errorStore = new stores.ErrorStore();
    const projectsService = new services.ProjectsService();
    const localStorageHelper = new LocalStorageWorker();
    const sessionService = new services.SessionService();
    const userProfleService = new UserProfileService();  
    const userProfileStore = new stores.UserProfileStore(userProfleService, errorStore);
    const projectsStore = new stores.ProjectsStore(projectsService, localStorageHelper, userProfileStore);
    const workflowInstance = new stores.WorkflowInstanceStore(sessionService, iotaSessionStore, userProfileStore);
    const fileStore = new stores.FileOperationsStore(workflowInstance, projectsStore);
    const applicationDefinitionsService = new services.ApplicationDefinitionsService();
    const applicationDefinitionsStore = new stores.ApplicationDefinitionsStore(applicationDefinitionsService);
    const tabsStore = new stores.TabsStore();
    const multiDocumentTabVisualStore = new stores.MultiDocumentTabVisualStore(tabsStore);
    const notificationStore = new stores.NotificationStore(tabsStore, projectsStore);
    const restricedAccessStore = new stores.RestricedAccessStore();

    return {
        projectsStore,
        errorStore,
        workflowInstance,
        fileStore,
        applicationDefinitionsStore,
        tabsStore,
        notificationStore,
        iotaSessionStore,
        multiDocumentTabVisualStore,
        userProfileStore,
        restricedAccessStore
    };
}

export type RootStore = ReturnType<typeof createStore>;

const storeContext = React.createContext<RootStore | null>(null);
type Props = {
    children: React.ReactNode
};

export const StoreProvider: React.FC<Props> = ({ children }) => {
    const store = useLocalStore(createStore);
    return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useStore = () => {
    const store = React.useContext(storeContext);
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error('You have forgot to use StoreProvider, shame on you.');
    }
    
    return store;
};
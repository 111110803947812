import { makeObservable, action, computed, observable, reaction } from 'mobx';
import { ContractIngestionAppRuntimeDto, ContractStatus } from '../types';
import { TabModel } from '../../common/types/TabModel';
import { TypeUtils } from '../../custom_shared/misc';
import { IotaSessionsStore } from '../../common/stores';
import { SessionStoreBase } from '../../custom_shared/stores';

export default class SessionStore extends SessionStoreBase<ContractIngestionAppRuntimeDto> {
    contractStatus: ContractStatus = null;

    constructor(tab: TabModel, iotaSessionsStore: IotaSessionsStore) {
        super(tab, iotaSessionsStore);

        makeObservable(this, {
            contractStatus: observable,
            setContractStatus: action,
            contractSending: computed,
            contractSent: computed,
            flowResponse: computed
        });

        this.reactions.push(
            reaction(
                () => this.response,
                () => {
                    if (this.response && !this.inProgress) {
                        this.setContractStatus(this.response.contractStatus);
                    }
                }
            )
        );
    }

    setContractStatus(contractStatus: ContractStatus) {
        this.contractStatus = contractStatus;
    }

    get contractSending() {
        return this.contractStatus == 'ContractSending';
    }

    get contractSent() {
        return this.contractStatus == 'ContractSent';
    }

    get flowResponse() {
        if (!this.response || !this.response.flowResponse) {
            return null;
        }

        return {
            contractTitle: TypeUtils.isString(this.response.flowResponse.contractTitle)
                ? this.response.flowResponse.contractTitle
                : null,
            programId: TypeUtils.isString(this.response.flowResponse.programId)
                ? this.response.flowResponse.programId
                : null,
            businessIds: TypeUtils.isStringArray(this.response.flowResponse.businessIds)
                ? this.response.flowResponse.businessIds
                : [],
            isRenewal: TypeUtils.isBoolean(this.response.flowResponse.isRenewal)
                ? this.response.flowResponse.isRenewal
                : false
        };
    }

    dispose() {
        this.disposeReactions();
    }
}

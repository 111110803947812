import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { GenericDatePicker } from '../../components';
import { InputModel } from '../../models';
import { ValidationMessages } from '../../misc';
import { InputWithoutSource } from '../../componentTypes';

const config = {
    label: 'Period end'
};

const PeriodEndInput: InputWithoutSource = ({ input }) => (
    <GenericDatePicker label={config.label} input={input} message={ValidationMessages.requiredInput(config.label)} />
);

PeriodEndInput.meta = {
    inputType: InputModel.name
};

export default observer(PeriodEndInput);

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { InputBaseModel } from '../../models';
import { InputRenderer } from '../../components';
import { TabProps } from '../../componentTypes';
import {
    InstalmentsCountInput,
    InstalmentTypeInput,
    FirstInstalmentCountInput,
    FirstInstalmentPeriodInput,
    SubsequentInstalmentCountInput,
    SubsequentInstalmentPeriodInput
} from '../../tab_inputs/InstalmentsTabInputs';
import { LayoutRenderer } from '../../layout_renderers';
import { Utils } from '../../misc';
import { useTabForm } from '../../hooks';

const InputIds = {
    InstalmentsCount: 'InstalmentsCount',
    InstalmentType: 'InstalmentType',
    FirstInstalmentCount: 'FirstInstalmentCount',
    FirstInstalmentPeriod: 'FirstInstalmentPeriod',
    SubsequentInstalmentCount: 'SubsequentInstalmentCount',
    SubsequentInstalmentPeriod: 'SubsequentInstalmentPeriod'
} as const;

const InputComponents: Record<string, React.FunctionComponent> = {
    [InputIds.InstalmentsCount]: InstalmentsCountInput,
    [InputIds.InstalmentType]: InstalmentTypeInput,
    [InputIds.FirstInstalmentCount]: FirstInstalmentCountInput,
    [InputIds.FirstInstalmentPeriod]: FirstInstalmentPeriodInput,
    [InputIds.SubsequentInstalmentCount]: SubsequentInstalmentCountInput,
    [InputIds.SubsequentInstalmentPeriod]: SubsequentInstalmentPeriodInput
} as const;

const InstalmentsTabRenderer: React.FC<TabProps> = ({ tab, addFormRef }: TabProps) => {
    const { form, formKey, onValuesChange } = useTabForm(tab, addFormRef);

    const getComponentRows = React.useCallback(
        (inputs: InputBaseModel[]) =>
            Utils.groupArray(inputs, 4).map(inputGroup =>
                inputGroup.map(input => (
                    <InputRenderer key={input.id} input={input} component={InputComponents[input.id]} />
                ))
            ),
        []
    );

    return (
        <LayoutRenderer
            tab={tab}
            form={form}
            formKey={formKey}
            onValuesChange={onValuesChange}
            getComponentRows={getComponentRows}
        />
    );
};

export default observer(InstalmentsTabRenderer);
